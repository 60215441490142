import {io, Socket} from "socket.io-client";
import {UserActionType, userReducer} from "../reducers/usersReducer";

export class SocketClient {
    private socket: Socket;

    constructor(url, jwt) {
        this.socket = io(url, {query: {jwt}, transports: ['websocket']});

        this.socket.on('online', user => {
            let value = {event: "online", user};
            console.log(value);
            userReducer.dispatch({type: UserActionType.ONLINE, user: value.user});
        });

        this.socket.on('offline', user => {
            let value = {event: "offline", user};
            console.log(value);
            userReducer.dispatch({type: UserActionType.OFFLINE, user: value.user});
        });

        this.socket.on('snapshot', users => {
            let value = {event: "snapshot", users};
            console.log(value);
            userReducer.dispatch({type: UserActionType.SNAPSHOT, users: value.users});
        });
    }

    close() {
        this.socket.disconnect();
    }
}
