import {Action, createStore, Store} from "redux";

export enum UserActionType {
    ONLINE,
    OFFLINE,
    SNAPSHOT,
    CLEAR,
}

export interface UserAction extends Action<UserActionType> {
    user?: { [p: string]: any },
    users?: { [p: string]: any }[]
}

export const userReducer: Store<any, UserAction> = createStore<any, UserAction, any, any>((state, action) => {
    switch (action.type) {
        case UserActionType.ONLINE:
            state[action.user?.id] = action.user;
            return state;
        case UserActionType.OFFLINE:
            delete state[action.user?.id];
            return state;
        case UserActionType.SNAPSHOT:
            return action.users;
        case UserActionType.CLEAR:
            return {};
    }
}, {});
