import {Action, createStore, Store} from "redux";

export enum AuthActionType {
    LOGIN,
    LOGOUT,
}

export interface AuthAction extends Action<AuthActionType> {
}

export const authReducer: Store<any, AuthAction> = createStore<any, AuthAction, any, any>((state, action) => {
    return action.type;
});
