import React, {useState} from "react";
import jwtDecode from "jwt-decode";
import {COOKIE_KEY, CookieManager} from "./CookieManager";
import {sign} from "jsonwebtoken";
import {AuthActionType, authReducer} from "./reducers/authReducer";

export function AuthLogin(props) {
    return (
        <div className="container pt-3">
            <ExternalLoginDiv/>
            <ManualJwtLoginDiv/>
            <ManualJsonLoginDiv/>
        </div>
    );
}

function ExternalLoginDiv(props) {
    const loginUri = 'https://ssotest.cpm.org/?redirect_uri=' + window.location.href;
    return (
        <div className="card form-group">
            <div className="card-header">
                <h5 className="m-0">Login</h5>
            </div>
            <div className="card-body">
                <a href={loginUri} className="btn btn-info">
                    LOGIN <small className="text-warning">{loginUri}</small></a>
            </div>
        </div>
    );
}

function ManualJsonLoginDiv(props) {
    const [value, setValue] = useState<string>();

    function jsonError() {
        try {
            JSON.parse(value as string);
        } catch (e) {
            return e;
        }
        return null;
    }

    function jsonValidityMessage() {
        const error = jsonError();
        if (error) {
            return error.message;
        }
        return "JSON is valid.";
    }

    function login() {
        CookieManager.add(COOKIE_KEY, sign(value as string, 'private_key'));
        authReducer.dispatch({type: AuthActionType.LOGIN});
    }

    return (
        <div className="card form-group">
            <div className="card-header">
                <h5 className="m-0">Generate JWT from JSON</h5>
            </div>
            <div className="card-body">
                        <textarea className="form-control" rows={5} placeholder="Enter JSON object here..."
                                  value={value}
                                  onChange={event => setValue(event.target.value)}/>
                <div>{jsonValidityMessage()}</div>
                <div className="text-right">
                    <button className="btn btn-info" onClick={login} disabled={jsonError()}>Login
                    </button>
                </div>
            </div>
        </div>
    );
}

function ManualJwtLoginDiv(props) {
    const [value, setValue] = useState<string>();

    function jwtError() {
        try {
            jwtDecode(value as string);
        } catch (e) {
            return e;
        }
        return null;
    }

    function jwtValidityMessage() {
        const error = jwtError();
        if (error) {
            return error.message;
        }
        return "JWT is valid.";
    }

    function login() {
        CookieManager.add(COOKIE_KEY, value);
        authReducer.dispatch({type: AuthActionType.LOGIN});
    }

    return (
        <div className="card form-group">
            <div className="card-header">
                <h5 className="m-0">Set JWT Manually</h5>
            </div>
            <div className="card-body">
                        <textarea className="form-control" rows={5} placeholder="Enter JWT here..."
                                  value={value}
                                  onChange={event => setValue(event.target.value)}/>
                <div>{jwtValidityMessage()}</div>
                <div className="text-right">
                    <button className="btn btn-info" onClick={login} disabled={jwtError()}>Login</button>
                </div>
            </div>
        </div>
    );
}
