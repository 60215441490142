import {parse, serialize} from "cookie";

export class CookieManager {
    static DOMAIN = '.cpm.org';
    static PATH = '/';

    static add(key, value) {
        window.document.cookie = serialize(key, value, {
            domain: this.DOMAIN,
            path: this.PATH,
            maxAge: 12 * 60 * 60,
        });
    }

    static remove(key) {
        window.document.cookie = serialize(key, '', {
            domain: this.DOMAIN,
            path: this.PATH,
            expires: new Date(1970, 1),
        });
    }

    static get(key) {
        const cookies = parse(window.document.cookie);
        return cookies[key];
    }
}

export const COOKIE_KEY = 'cpm-sso-token';