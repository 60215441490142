import React, {useEffect, useState} from "react";
import {AuthActionType, authReducer} from "./reducers/authReducer";
import {COOKIE_KEY, CookieManager} from "./CookieManager";
import {SocketClient} from "./users-socket/SocketClient";
import {UserActionType, userReducer} from "./reducers/usersReducer";
import * as _ from "lodash";
import {SOCKET_SERVER_URL} from "../constants";

export function Dashboard(props) {
    return (
        <div className="container-fluid pt-3">
            <div className="row">
                <div className="col-9">
                    {/*{renderUserDashboard()}*/}
                    <UserDashboard jwt={props.jwt} payload={props.payload}/>
                </div>
                <div className="col-3">
                    <InfoRightPanel jwt={props.jwt} payload={props.payload}/>
                </div>
            </div>
        </div>
    );
}

function UserDashboard(props) {
    const [usersState, setUsersState] = useState<any>({});

    useEffect(() => {
        const unsubscribe = userReducer.subscribe(() => {
            setUsersState(userReducer.getState());
        });

        let socketClient = new SocketClient(SOCKET_SERVER_URL, props.jwt);

        return () => {
            userReducer.dispatch({type: UserActionType.CLEAR});
            socketClient.close();
            unsubscribe();
        };
    }, [props.jwt]);

    function isTeacher() {
        return props.payload?.realm_access?.roles.includes("FACULTY");
    }

    function logout() {
        CookieManager.remove(COOKIE_KEY);
        authReducer.dispatch({type: AuthActionType.LOGOUT});
    }

    return (
        <div className="row">
            <div className="col-8">
                {<h1>Hi, <span
                    className="text-info">{props.payload?.given_name} {props.payload?.family_name}</span>!
                </h1>}
                <button className="btn btn-info" onClick={logout}>Logout</button>
            </div>
            <div className="col-4">
                {isTeacher() ? <OnlineUsersPanel payload={props.payload} usersState={usersState} /> : ''}
            </div>
        </div>
    );
}

function InfoRightPanel(props) {
    return (
        <div>
            <div className="form-group">
                <label>JWT</label>
                <ReadonlyTextarea value={props.jwt}/>
            </div>
            <div className="form-group">
                <label>JSON payload</label>
                <ReadonlyTextarea value={JSON.stringify(props.payload, null, 4)}/>
            </div>
        </div>
    );
}

function ReadonlyTextarea(props) {
    return (
        <textarea className="form-control" rows={props.rows ?? 15} readOnly
                  value={props.value}
                  onFocus={event => event.target.select()}/>
    );
}

function OnlineUsersPanel({payload, usersState}) {
    useEffect(() => {
        console.log(1);
    }, [usersState]);

    const getClassStudents = (classInfo): any => {
        return (Object.values(usersState) ?? []).filter((student: any) => {
            return student.classes.find(_class => _class.classId === classInfo.classId);
        });
    };

    return (
        <div>
            <h4>Online Users</h4>
            <hr/>
            {payload?.realm_access?.classes.map(classInfo => (
                <div key={classInfo.classId}>
                    <h5>Class: {classInfo.classId}</h5>
                    <ul>
                        {getClassStudents(classInfo).map(user => (
                            <li key={user.id}>{user.name}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}