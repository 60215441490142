import React, {useEffect, useState} from "react";
import {COOKIE_KEY, CookieManager} from "./CookieManager";
import {AuthLogin} from "./AuthLogin";
import {Dashboard} from "./Dashboard";
import {authReducer} from "./reducers/authReducer";
import {decode} from "jsonwebtoken";

export interface JwtState {
    jwt: string,
    payload: any,
}

export function Homepage(props) {
    const jwt = CookieManager.get(COOKIE_KEY);
    const payload = decode(jwt);
    const [jwtState, dispatchJwtState] = useState<JwtState>({jwt, payload});

    useEffect(() => {
        const unsubscribe = authReducer.subscribe(() => {
            const jwt = CookieManager.get(COOKIE_KEY);
            console.log(decode(jwt));
            const payload = decode(jwt);
            dispatchJwtState({jwt, payload});
        });

        return () => unsubscribe();
    }, []);

    return (
        <div>
            {jwtState.jwt ? <Dashboard jwt={jwtState.jwt} payload={jwtState.payload}/> : <AuthLogin/>}
        </div>
    );
}
